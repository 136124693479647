import { actionsMap } from "#Components/AutoDeployment";
import { InfoColumn, InfoTable } from "#Components/InfoTable";
import Switch from "#Components/Switch";
import { agentBackupAction, backupAction } from "#Constants/Endpoints";
import { JSONPath } from "jsonpath-plus";
import moment from "moment";
import { Button, Col, Row } from "reactstrap";

const DeploymentBackups = (props) => {
  const { userRoleInOrg } = JSON.parse(sessionStorage.getItem("Clouve.object"));
  const {
    ticketDetails,
    deploymentDetails,
    applyAction = (x) => {},
    actionButtonsDisabled = false,
  } = props;
  const backupList = deploymentDetails.backups
    ? [...deploymentDetails.backups].sort(
        (a, b) =>
          new Date(b.metadata?.creationTimestamp) -
          new Date(a.metadata?.creationTimestamp),
      )
    : [];
  const scheduleList =
    JSONPath({
      json: deploymentDetails.schedules,
      path: `$..metadata.name`,
      resultType: "value",
    }) || [];
  const { id, deployment } = ticketDetails;
  const { orgId } = deployment;
  const namespace = `org-${orgId}-tkt-${id}`;
  const backupFrequencies = [
    {
      backupFrequency: "daily",
      scheduleLabel: "Every Day",
      scheduleId: `${namespace}-daily-backup`,
    },
    {
      backupFrequency: "weekly",
      scheduleLabel: "Every Week",
      scheduleId: `${namespace}-weekly-backup`,
    },
    {
      backupFrequency: "monthly",
      scheduleLabel: "Every Month",
      scheduleId: `${namespace}-monthly-backup`,
    },
    {
      backupFrequency: "yearly",
      scheduleLabel: "Every Year",
      scheduleId: `${namespace}-yearly-backup`,
    },
  ];
  if (location.hostname.match(/^(localhost|e2e\.app\.clouve\.com)$/gi)) {
    backupFrequencies.push({
      backupFrequency: "minutely",
      scheduleLabel: "Every Minute (Testing)",
      scheduleId: `${namespace}-minutely-backup`,
    });
  }

  const renderActions = ({ metadata }, index, column, id) => {
    return (
      <>
        <Button
          disabled={actionButtonsDisabled}
          className="font-12"
          size="sm"
          color="success"
          onClick={() =>
            applyAction({
              actionTitle: "Restore",
              actionTarget: metadata?.name,
              ticketAction: userRoleInOrg.isAgent
                ? agentBackupAction
                : backupAction,
              actionConfig: {
                backupAction: "restore",
                backupId: metadata?.name,
              },
            })
          }
        >
          Restore
        </Button>
        <Button
          disabled={actionButtonsDisabled}
          className="ms-2 font-12"
          size="sm"
          color="danger"
          onClick={() =>
            applyAction({
              actionTitle: "Delete",
              actionTarget: metadata?.name,
              ticketAction: userRoleInOrg.isAgent
                ? agentBackupAction
                : backupAction,
              actionConfig: {
                backupAction: "delete",
                backupId: metadata?.name,
              },
            })
          }
        >
          Delete
        </Button>
      </>
    );
  };

  return (
    <div className="p-2">
      <Row className="mt-2 mb-2">
        <Col xs="auto" className="d-flex align-items-center">
          <strong>Schedule Regular Backups:</strong>
        </Col>
        <Col xs="auto">
          <Row>
            {backupFrequencies.map(
              ({ backupFrequency, scheduleLabel, scheduleId }, idx) => (
                <Col sm="auto" key={idx}>
                  <Switch
                    id={scheduleId}
                    name={scheduleId}
                    label={scheduleLabel}
                    disabled={actionButtonsDisabled}
                    checked={scheduleList.includes(scheduleId)}
                    onChange={(checked) => {
                      applyAction({
                        ticketAction: userRoleInOrg.isAgent
                          ? agentBackupAction
                          : backupAction,
                        actionConfig: {
                          backupAction: checked ? "schedule" : "delete",
                          backupFrequency,
                          scheduleId,
                        },
                      });
                    }}
                  />
                </Col>
              ),
            )}
          </Row>
        </Col>
        <Col>
          <Button
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["backup"]}
            onClick={() =>
              applyAction({
                ticketAction: userRoleInOrg.isAgent
                  ? agentBackupAction
                  : backupAction,
                actionConfig: {
                  backupAction: "create",
                },
              })
            }
          >
            BACKUP NOW
          </Button>
        </Col>
      </Row>
      <InfoTable
        data={backupList}
        pagination={backupList.length > 10}
        hideSearch={true}
        highlightOnHover
        striped
        dense
      >
        <InfoColumn
          selector={(row) => row.metadata?.creationTimestamp}
          format={(row) =>
            `${moment(row.metadata?.creationTimestamp).format(
              "MMM DD, YYYY h:mm A",
            )}`
          }
          sortable
        >
          Date Created
        </InfoColumn>
        <InfoColumn
          selector={(row) => row.status?.expiration}
          format={(row) =>
            `${moment(row.status?.expiration).format("MMM DD, YYYY h:mm A")}`
          }
          sortable
        >
          Expires
        </InfoColumn>
        <InfoColumn cell={renderActions} sortable center>
          Actions
        </InfoColumn>
      </InfoTable>
    </div>
  );
};
export default DeploymentBackups;
