import { actionsMap } from "#Components/AutoDeployment";
import {
  cancelSubscriptionAction,
  cancelTrialAction,
  cloneAction,
  deleteAction,
} from "#Constants/Endpoints";
import { isSubscriptionStatusAny, isTrialExpired } from "#hoc/util";
import { Button, Col, Row } from "reactstrap";

const UserActions = (props) => {
  const {
    ticketDetails,
    formDataDirty = false,
    actionButtonsDisabled = false,
    applyAction = (x) => {},
    onSubscribe = () => {},
    onSaveAndApply = () => {},
  } = props;

  const { deployment = {} } = ticketDetails;
  const subscribeBtnLabel =
    isSubscriptionStatusAny(deployment, ["trial_canceled", "canceled"]) ||
    isTrialExpired(deployment)
      ? "SUBSCRIBE TO REACTIVATE"
      : formDataDirty
        ? "SUBSCRIBE & APPLY"
        : "SUBSCRIBE NOW";

  const renderCloneAction = () => {
    if (isSubscriptionStatusAny(deployment, ["pending", "active", "exempt"])) {
      return (
        <Col sm={3}>
          <Button
            block
            size="sm"
            disabled={actionButtonsDisabled}
            color={actionsMap["clone"]}
            onClick={() => applyAction({ ticketAction: cloneAction })}
          >
            <i className="fa fa-clone me-1" /> Clone
          </Button>
        </Col>
      );
    }
    return null;
  };

  return (
    <div className="user-actions mt-2">
      <Row className="d-flex justify-content-center">
        {isSubscriptionStatusAny(deployment, ["active", "exempt"]) ? (
          <Col sm={6} className="m-1">
            <Button
              block
              color="primary"
              onClick={onSaveAndApply}
              disabled={actionButtonsDisabled || !formDataDirty}
            >
              SAVE & APPLY
            </Button>
          </Col>
        ) : null}
        {isSubscriptionStatusAny(deployment, [
          "trial",
          "trial_canceled",
          "canceled",
        ]) || isTrialExpired(deployment) ? (
          <Col sm={6} className="m-1">
            <Button
              block
              disabled={actionButtonsDisabled}
              color={actionsMap["subscribe"]}
              onClick={onSubscribe}
            >
              {subscribeBtnLabel}
            </Button>
          </Col>
        ) : null}
      </Row>
      <Row className="d-flex justify-content-center">
        {renderCloneAction()}
        {isSubscriptionStatusAny(deployment, ["active", "pending"]) ? (
          <Col sm={3} className="m-1">
            <Button
              block
              size="sm"
              disabled={actionButtonsDisabled}
              color={actionsMap["cancel_subscription"]}
              onClick={() =>
                applyAction({ ticketAction: cancelSubscriptionAction })
              }
            >
              <i className="fa fa-power-off me-1" /> Cancel Subscription
            </Button>
          </Col>
        ) : null}
        {isSubscriptionStatusAny(deployment, ["trial"]) &&
        !isTrialExpired(deployment) ? (
          <Col sm={3} className="m-1">
            <Button
              block
              size="sm"
              disabled={actionButtonsDisabled}
              color={actionsMap["delete"]}
              onClick={() => applyAction({ ticketAction: cancelTrialAction })}
            >
              <i className="fa fa-power-off me-1" /> Cancel Trial
            </Button>
          </Col>
        ) : null}
        {isSubscriptionStatusAny(deployment, [
          "exempt",
          "canceled",
          "trial_canceled",
        ]) ||
        (isSubscriptionStatusAny(deployment, ["trial"]) &&
          isTrialExpired(deployment)) ? (
          <Col sm={3} className="m-1">
            <Button
              block
              size="sm"
              disabled={actionButtonsDisabled}
              color={actionsMap["delete"]}
              onClick={() => applyAction({ ticketAction: deleteAction })}
            >
              <i className="fa fa-trash me-1" /> Delete
            </Button>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default UserActions;
