import Collapsable from "#Components/Collapsable";
import DynamicForm, {
  CAPACITY_ONLY,
  CLOUD_ONLY,
  DOMAINS_ONLY,
} from "#Components/DynamicForm";
import { validateClusterFields } from "#Components/DynamicForm/helpers";
import InputForm, {
  SubmitButton,
  TextareaField,
  TextField,
} from "#Components/InputForm";
import { SolutionItem } from "#Components/SolutionItem";
import TabNav from "#Components/TabNav";
import { submitCreateDeployment } from "#Graphql/mutate";
import withRouter from "#hoc/withRouter";
import _ from "lodash";

import moment from "moment";
import React, { PureComponent } from "react";
import { withAlert } from "react-alert";
import { Alert, Button, Col, Label, Row, Spinner } from "reactstrap";
import confirm from "reactstrap-confirm";

const resetState = {
  title: "",
  description: "",
  jsonSchema: {
    schema: null,
    uiSchema: null,
    formData: {
      cluster: {
        capacity: "",
        dnsName: "",
        extraDnsName: "",
      },
    },
  },
  extraAttr: {},
  extraErrors: {},
  isLoading: false,
  blurValidationPending: false,
};

const validate = (values) => {
  const errors = {};
  const requiredFields = ["title"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "This field is required";
    }
  });
  return errors;
};

class CreateDeploymentForm extends PureComponent {
  state = { ...resetState };

  componentDidMount() {
    this.resetFormFields();
  }

  componentDidUpdate(prevProps, prevState) {
    const { orgTicketId } = this.props;
    if (prevProps.orgTicketId !== orgTicketId) {
      this.resetFormFields();
    }
  }

  resetFormFields = () => {
    const { orgTicketId, solutionItem } = this.props;
    const { serviceAttributes, selectedCapacity } = solutionItem;
    const { serviceTitle, serviceVersion, serviceDescription } =
      serviceAttributes;
    const newState = {
      ...resetState,
      title: `${serviceTitle} ${serviceVersion || ""}`,
      description: serviceDescription,
      extraAttr: { selfServiceType: solutionItem.serviceName },
    };
    _.set(
      newState,
      "jsonSchema.formData.cluster.capacity",
      selectedCapacity || "x-small",
    );
    _.set(newState, "jsonSchema.formData.cluster.dnsName", orgTicketId);
    this.setState(newState);
  };

  errorHandler = (errors) => {
    this.setState({ isLoading: false });
    this.props.alert.error(errors.message);
  };

  showSuccessMessage = (message) => {
    this.setState({ isLoading: false });
    this.props.alert.success(message);
  };

  createDeploymentSubmit = async (deploymentData) => {
    const { history } = this.props;
    try {
      this.setState({ isLoading: true });
      const { createDeployment } = await submitCreateDeployment({
        deploymentData,
      });
      if (createDeployment && createDeployment.code === 200) {
        const { message, deploymentTicketData } = createDeployment;
        this.showSuccessMessage(message);
        if (deploymentTicketData) {
          history.push(`/deployment/${deploymentTicketData.orgTicketId}`);
        }
      } else {
        this.errorHandler(createDeployment);
      }
    } catch (err) {
      this.errorHandler(err);
    }
  };

  onSchemaChange = async (schema, uiSchema) => {
    const { jsonSchema } = this.state;
    this.setState({ jsonSchema: { ...jsonSchema, schema, uiSchema } });
  };

  onFormDataFocus = async (fieldId, fieldValue) => {
    this.setState({ blurValidationPending: true });
  };

  onFormDataChange = async (formData, errors, fieldId) => {
    const { jsonSchema } = this.state;
    this.setState({ jsonSchema: { ...jsonSchema, formData, errors } }, () => {
      if (fieldId && fieldId.match(/_capacity$/gi)) {
        this.onFormDataBlur();
      }
    });
  };

  onFormDataBlur = async () => {
    const { solutionItem } = this.props;
    const { jsonSchema } = this.state;
    const { formData } = jsonSchema;
    const { servicePlans } = solutionItem;
    const extraErrors = await validateClusterFields({
      formData,
      servicePlans,
    });
    this.setState({ extraErrors, blurValidationPending: false });
  };

  handleCapacitySelect = async (capacity) => {
    const { jsonSchema } = this.state;
    const { formData } = jsonSchema;
    if (formData) {
      const { cluster = {} } = formData;
      this.setState({
        jsonSchema: {
          ...jsonSchema,
          formData: { ...formData, cluster: { ...cluster, capacity } },
        },
      });
    }
  };

  onInstall = async ({ title, urgency, description }) => {
    const { solutionItem } = this.props;
    const { jsonSchema, extraAttr } = this.state;
    const { formData } = jsonSchema;
    const { servicePlans, serviceAttributes } = solutionItem;
    const { cluster } = formData;
    const { capacity } = cluster;
    const planItem = servicePlans[capacity];

    const confirmProps = {
      title: <>Install {title}?</>,
      message: (
        <div>
          <p>
            Are you sure you want to install <strong>{title}</strong>?
          </p>
        </div>
      ),
      confirmText: "Proceed",
      confirmColor: "primary",
      cancelText: "Cancel",
      cancelColor: "link",
    };

    const proceed = await confirm(confirmProps);
    if (!proceed) return;

    await this.createDeploymentSubmit({
      type: "DEPLOYMENT",
      title,
      urgency,
      description,
      jsonSchema,
      extraAttr,
      planId: planItem.id,
      attributes: serviceAttributes,
      currentTime: moment().format(),
    });
  };

  onSubscribeAndInstall = ({ title, urgency, description }) => {
    const { solutionItem, history } = this.props;
    const { jsonSchema, extraAttr } = this.state;
    const { formData } = jsonSchema;
    const { servicePlans, serviceAttributes } = solutionItem;
    const { cluster } = formData;
    const { capacity } = cluster;
    const planItem = servicePlans[capacity];

    history.push("/checkout", {
      ticketDetails: {
        type: "DEPLOYMENT",
        title,
        urgency,
        description,
        jsonSchema,
        extraAttr,
        planId: planItem.id,
        attributes: serviceAttributes,
        currentTime: moment().format(),
      },
      solutionItem,
    });
  };

  onCancel = () => {
    const { onCancel = () => {} } = this.props;
    onCancel();
  };

  renderDynamicForm = (overrideProps = {}) => {
    const { solutionItem } = this.props;
    const { jsonSchema, extraAttr, extraErrors } = this.state;
    if (extraAttr.selfServiceType) {
      const newTicketDetails = {
        jsonSchema,
        extraAttr,
        attributes: solutionItem.serviceAttributes,
      };
      return (
        <DynamicForm
          onSchemaChange={this.onSchemaChange}
          onFormDataChange={this.onFormDataChange}
          onFormDataFocus={this.onFormDataFocus}
          onFormDataBlur={this.onFormDataBlur}
          ticketDetails={newTicketDetails}
          extraErrors={extraErrors}
          {...overrideProps}
        />
      );
    } else {
      return <Spinner />;
    }
  };

  renderCloud = () => {
    return <div className="p-3">{this.renderDynamicForm(CLOUD_ONLY)}</div>;
  };

  renderCapacity = () => {
    const { solutionItem } = this.props;
    const { jsonSchema } = this.state;
    const { formData } = jsonSchema;
    const { cluster } = formData;
    const { capacity } = cluster;
    return (
      <div className="p-3">
        {this.renderDynamicForm(CAPACITY_ONLY)}
        <SolutionItem
          solutionItem={solutionItem}
          selectedCapacity={capacity}
          onCapacitySelect={this.handleCapacitySelect}
        />
      </div>
    );
  };

  renderDomains = () => {
    return <>{this.renderDynamicForm(DOMAINS_ONLY)}</>;
  };

  renderReview = () => {
    const { solutionItem } = this.props;
    const { title, description, jsonSchema = {} } = this.state;
    const { formData } = jsonSchema;
    const { cluster } = formData;
    const { capacity } = cluster;

    return (
      <div className="p-3">
        <Row>
          <Col sm={12}>
            <Label>App Title *</Label>
            <TextField name="title" value={title} />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label>App Description</Label>
            <TextareaField name="description" value={description} />
          </Col>
        </Row>

        <Collapsable label="App Configuration" collapsed={true}>
          {this.renderDynamicForm()}
        </Collapsable>

        <Collapsable label="App Capacity">
          <SolutionItem
            solutionItem={solutionItem}
            selectedCapacity={capacity}
          />
        </Collapsable>
      </div>
    );
  };

  onSubmit = async ({ formFields }, evt) => {
    if (
      evt.target.id === "installButton" ||
      evt.target.id === "installTrialButton"
    ) {
      await this.onInstall(formFields);
    } else if (evt.target.id === "subscribeInstallButton") {
      this.onSubscribeAndInstall(formFields);
    }
  };

  render = () => {
    const { org, fta } = JSON.parse(sessionStorage.getItem("Clouve.object"));
    const { solutionItem } = this.props;
    const {
      title,
      description,
      jsonSchema = {},
      isLoading,
      blurValidationPending,
      extraErrors,
    } = this.state;

    const { formData } = jsonSchema;
    const { cluster } = formData;
    const { capacity, extraDnsName } = cluster;
    const { serviceAttributes = {}, servicePlans = {} } = solutionItem;
    const { suiteApps } = serviceAttributes;
    const planItem = servicePlans[capacity] || servicePlans["x-small"];
    const { trialDuration = 0 } = planItem;

    return (
      <div className="ticket-form">
        <div className="text-center mt-4">
          <h4>Install {title}</h4>
          <h5>
            Get your {suiteApps ? "bundle" : "application"} up and running in
            just a few minutes
          </h5>
        </div>
        <div className="add-ticket-body mt-5">
          {isLoading && (
            <div className="loading-spinner">
              <Spinner type="grow" />
            </div>
          )}
          <InputForm
            initialValues={{
              title,
              description,
              urgency: "minor",
              assigneeId: fta?.id,
              assignee: fta?.email,
            }}
            validator={validate}
            onSubmit={this.onSubmit}
          >
            <TabNav
              className="mb-4"
              tabs={[
                {
                  tabId: "/cloud",
                  tabLabel: "Cloud",
                  tabComponent: this.renderCloud(),
                },
                {
                  tabId: "/capacity",
                  tabLabel: "Capacity",
                  tabComponent: this.renderCapacity(),
                },
                {
                  tabId: "/domains",
                  tabLabel: "Domains",
                  tabComponent: this.renderDomains(),
                },
                {
                  tabId: "/review",
                  tabLabel: "Review",
                  tabComponent: this.renderReview(),
                },
              ]}
              width="large"
            />
            {(capacity !== "x-small" || extraDnsName) && !org.exempt ? (
              <Row className="mb-0">
                <Col className="text-center">
                  <Alert color="warning">
                    A subscription is required to select a{" "}
                    <strong>Capacity</strong> larger than x-small or to utilize{" "}
                    <strong>Custom Domain(s)</strong>. <br />
                    <br />
                    For a comprehensive experience you're encouraged to{" "}
                    <strong>SUBSCRIBE & INSTALL</strong> this deployment.
                  </Alert>
                </Col>
              </Row>
            ) : null}
            <Row>
              {org.exempt ? (
                <Col className="text-center">
                  <SubmitButton
                    id="installButton"
                    block
                    color="primary"
                    disabled={
                      blurValidationPending || !!Object.keys(extraErrors).length
                    }
                  >
                    INSTALL
                  </SubmitButton>
                </Col>
              ) : (
                <>
                  <Col className="text-center">
                    <SubmitButton
                      id="installTrialButton"
                      block
                      color="primary"
                      disabled={
                        blurValidationPending ||
                        capacity !== "x-small" ||
                        extraDnsName
                      }
                    >
                      INSTALL {`${trialDuration}-day`} TRIAL
                    </SubmitButton>
                  </Col>
                  <Col className="text-center">
                    <SubmitButton
                      id="subscribeInstallButton"
                      block
                      color="success"
                      disabled={
                        blurValidationPending ||
                        !!Object.keys(extraErrors).length
                      }
                    >
                      SUBSCRIBE & INSTALL
                    </SubmitButton>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Col className="text-center">
                <Button size="sm" color="link" onClick={this.onCancel}>
                  CANCEL
                </Button>
              </Col>
            </Row>
          </InputForm>
        </div>
      </div>
    );
  };
}

export default withRouter(withAlert()(CreateDeploymentForm));
