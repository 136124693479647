import { verifyToken } from "#hoc/auth";
import React from "react";

const CDN_SERVER = `https://${window.VITE_CDN_HOSTNAME || "cdn.clouve.com"}`;

const Logo = (props) => {
  const { protocol, hostname, port } = window.location;
  const hostnameParts = hostname.replace(/app\./gi, "").split(".");
  if (hostnameParts.length < 3) {
    hostnameParts.unshift("www");
  }
  const newHostname = hostnameParts.join(".");
  const homeURL = `${protocol}//${newHostname}${port ? `:${port}` : ""}`;
  const classNames = ["logo-wrapper"];
  if (hostname.match(/clouve\.dev$/gi) || hostname.match(/^dev\.app/gi)) {
    classNames.push("dev-env");
  } else if (hostname.match(/^demo\.app/gi)) {
    classNames.push("demo-env");
  }
  return (
    <h3 className={classNames.join(" ")}>
      <a href={verifyToken() ? "/" : homeURL}>
        <img
          className="logo"
          alt=""
          src={`${CDN_SERVER}/assets/ClouveLogo.svg`}
        />
      </a>
    </h3>
  );
};

export default Logo;
