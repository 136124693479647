import { actionsMap, buildMap } from "#Components/AutoDeployment";
import Collapsable from "#Components/Collapsable";
import {
  agentCloneAction,
  agentDeleteAction,
  agentDisableSSOAction,
  agentEnableSSOAction,
  agentLaunchAction,
  agentPauseAction,
  agentResumeAction,
  agentStatusAction,
  agentTokenAction,
  agentUpgradeAction,
  pauseAction,
  resumeAction,
  upgradeAction,
} from "#Constants/Endpoints";
import { downloadContentAsFile, getRemainingTime } from "#hoc/util";
import { Badge, Button, Col, Row, Table } from "reactstrap";

const AgentActions = (props) => {
  const { user } = JSON.parse(sessionStorage.getItem("Clouve.object"));
  const labelWidth = 150;
  const {
    ticketDetails,
    agentToken,
    selfServiceBuildStatus = {},
    actionButtonsDisabled = false,
    applyAction = (x) => {},
  } = props;
  const { buildStatus = "" } = selfServiceBuildStatus;
  const { deployment = {}, currentAssignee = {} } = ticketDetails;
  const { deploymentAction = "" } = deployment;
  const actionColor = actionsMap[deploymentAction?.toLowerCase()];
  const actionLabel = deploymentAction?.split("_").join(" ");
  const buildColor = buildMap[buildStatus?.toLowerCase()];
  const agentTokenStatus = getRemainingTime(
    agentToken?.tokenObj?.status?.expirationTimestamp,
  );
  const isAssignee = currentAssignee.id === user.id;
  const badgeColor =
    agentTokenStatus.remainingSeconds > 0 ? "warning" : "danger";
  return (
    <Collapsable label="Privileged Actions - Visible To Agent Only" small red>
      <div className="service-actions-wrapper warning-box">
        <Table size="sm" borderless>
          <tbody>
            {buildStatus && (
              <>
                <tr>
                  <th width={labelWidth}>Namespace:</th>
                  <td>{`org-${deployment.orgId}-tkt-${ticketDetails.id}`}</td>
                </tr>
                {isAssignee ? (
                  <tr>
                    <th width={labelWidth}>Token Status:</th>
                    <td>
                      {agentTokenStatus.remainingSeconds > 0 ? (
                        <>
                          Expires in{" "}
                          <Badge
                            color={badgeColor}
                            title={agentTokenStatus.expireDate}
                          >
                            {agentTokenStatus.remainingHours > 0 ? (
                              <>{agentTokenStatus.remainingHours} hour(s)</>
                            ) : (
                              <>
                                {agentTokenStatus.remainingSeconds} seconds(s)
                              </>
                            )}
                          </Badge>{" "}
                          (
                          <span
                            className="inline-link"
                            onClick={() => {
                              downloadContentAsFile({
                                content: agentToken.kubeConfigObj.yaml,
                                fileName: agentToken.kubeConfigObj.file,
                              });
                            }}
                          >
                            download
                          </span>
                          )
                        </>
                      ) : (
                        <>
                          <Badge color={badgeColor}>EXPIRED</Badge> (
                          <span
                            className="inline-link"
                            onClick={() =>
                              applyAction({
                                ticketAction: agentTokenAction,
                              })
                            }
                          >
                            Generate New Token
                          </span>
                          )
                        </>
                      )}
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <th width={labelWidth}>Subscription Id:</th>
                  <td>{deployment.subscriptionId || ""}</td>
                </tr>
                <tr>
                  <th width={labelWidth}>Last Action:</th>
                  <td>
                    <Badge color={actionColor}>
                      {(actionLabel + "").toUpperCase()}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <th width={labelWidth}>Action Status:</th>
                  <td>
                    <Badge color={buildColor}>
                      {(buildStatus + "").toUpperCase()}
                    </Badge>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th width={labelWidth}>Available Actions:</th>
              <td>
                <Row className="mt-2">
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["status"]}
                      onClick={() =>
                        applyAction({ ticketAction: agentStatusAction })
                      }
                    >
                      Status
                    </Button>
                  </Col>
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["resume"]}
                      onClick={() =>
                        applyAction({ ticketAction: agentResumeAction })
                      }
                    >
                      Resume
                    </Button>
                  </Col>
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["pause"]}
                      onClick={() =>
                        applyAction({ ticketAction: agentPauseAction })
                      }
                    >
                      Pause
                    </Button>
                  </Col>
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["delete"]}
                      onClick={() =>
                        applyAction({ ticketAction: agentDeleteAction })
                      }
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["launch"]}
                      onClick={() =>
                        applyAction({ ticketAction: agentLaunchAction })
                      }
                    >
                      Launch
                    </Button>
                  </Col>
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["launch"]}
                      onClick={() =>
                        applyAction({
                          ticketAction: agentCloneAction,
                        })
                      }
                    >
                      Clone
                    </Button>
                  </Col>
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["upgrade"]}
                      onClick={() =>
                        applyAction({ ticketAction: agentUpgradeAction })
                      }
                    >
                      Upgrade
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["launch"]}
                      onClick={() =>
                        applyAction({ ticketAction: agentEnableSSOAction })
                      }
                    >
                      Enable SSO
                    </Button>
                  </Col>
                  <Col className="p-1">
                    <Button
                      block
                      disabled={actionButtonsDisabled}
                      className="font-12"
                      size="sm"
                      color={actionsMap["launch"]}
                      onClick={() =>
                        applyAction({ ticketAction: agentDisableSSOAction })
                      }
                    >
                      Disable SSO
                    </Button>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </Collapsable>
  );
};

const ExemptActions = (props) => {
  const {
    ticketDetails,
    actionButtonsDisabled = false,
    applyAction = (x) => {},
  } = props;
  const { deployment = {} } = ticketDetails;
  const { deploymentAction = "" } = deployment;
  return (
    <div className="service-actions-wrapper warning-box">
      <div className="font-weight-bold mb-2 red">Privileged Organization</div>
      <Row className="m-1 d-flex justify-content-center">
        <Col className="p-1">
          <Button
            block
            disabled={
              actionButtonsDisabled ||
              (deploymentAction + "").match(/^delete$/gi)
            }
            className="font-12"
            size="sm"
            color={actionsMap["pause"]}
            onClick={() => applyAction({ ticketAction: pauseAction })}
          >
            Pause
          </Button>
        </Col>
        <Col className="p-1">
          <Button
            block
            disabled={actionButtonsDisabled}
            className="font-12"
            size="sm"
            color={actionsMap["resume"]}
            onClick={() => applyAction({ ticketAction: resumeAction })}
          >
            {(deploymentAction + "").match(/^delete$/gi)
              ? "Reinstall"
              : "Resume"}
          </Button>
        </Col>
        <Col className="p-1">
          <Button
            block
            disabled={
              actionButtonsDisabled ||
              (deploymentAction + "").match(/^delete$/gi)
            }
            className="font-12"
            size="sm"
            color={actionsMap["upgrade"]}
            onClick={() => applyAction({ ticketAction: upgradeAction })}
          >
            Upgrade
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const PrivilegedActions = (props) => {
  const { org, userRoleInOrg } = JSON.parse(
    sessionStorage.getItem("Clouve.object"),
  );
  if (userRoleInOrg.isAgent) {
    return <AgentActions {...props} />;
  } else if (org.exempt) {
    return <ExemptActions {...props} />;
  }
  return null;
};
export default PrivilegedActions;
