import React from "react";

const Clone = (props) => {
  const { disabled, onClick = () => {} } = props;
  if (disabled) {
    return (
      <span
        style={{ fontSize: "1.2rem" }}
        className="fa-stack fa-1x"
        title="Subscription Required"
      >
        <i className="gray-dark fa-solid fa-clone fa-stack-1x" />
        <i className="red fa-solid fa-ban fa-stack-2x" />
      </span>
    );
  }
  return (
    <span
      style={{ fontSize: "1.2rem" }}
      className="fa-stack fa-1x"
      title="Clone"
      onClick={onClick}
    >
      <i className="fa-solid fa-clone fa-stack-1x" />
      <i className="transparent fa-solid fa-ban fa-stack-2x" />
    </span>
  );
};

export default Clone;
